html, body, #root, .App {
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Inter;
}

h2 {
  color: #fff;
}

.mint-title {
  text-align: center;
  font-weight: bold;
  padding-bottom: 1rem;
}

.total-text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}


/**********
J Lawless Updates
***********/

.main-bod {
  position: relative;
}

.btn-primary {
  background: #0ccdcb !important;
  border: none !important;
  font-weight: 600 !important; 
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px !important;
}

.btn-primary:hover {
  background: #9f4fed !important;
  color: #f9ea31 !important;
}

.tag-btn {
  border-radius: 25px !important;
}

.card {
  background: rgba(0,0,0,0.7) !important;
  border-radius: 25px !important;
  color: #fff !important;
}

nav.navbar {
  /*background: #000 !important;*/
  background: rgba(0,0,0,0.7) !important;
}

img.main-logo {
  max-width: 182px;
}

img.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1;
}

.overlay {
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.4;
}

.footer-nav {
  background: none !important;
}

.footer-nav .navbar-text {
  color: #fff !important;
}

.footer-nav a {
  color: #fff !important;
}

@media screen and (max-width: 407px) {
  .mob-center {
    justify-content: center !important;
    padding-bottom: 15px;
  }
  .navbar-brand {
    margin-right: 0 !important;
    margin-bottom: 10px;
  }
}

.form-control {
  background: rgba(0,0,0,0) !important;
  border-color: rgba(255,255,255,0.25) !important;
  color: #fff !important;
  box-shadow: 0px 0px 0px !important;
}

.badge.bg-primary {
  background-color: #0ccdcb !important;
}

.progress {
  background-color: rgba(122, 75, 168, 0.4)!important;
}

.progress-bar {
  background-color: #9f4fed !important;
  font-weight: bold;
}